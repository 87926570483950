import React from 'react'
import NextLink from 'next/link'

import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { Theme, CSSObject } from '@mui/material/styles'

import DateRangeIcon from '@mui/icons-material/DateRange'
import CellTowerIcon from '@mui/icons-material/CellTower'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import HomeIcon from '@mui/icons-material/Home'
import AnnouncementIcon from '@mui/icons-material/Announcement'

import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'

type LayoutProps = React.PropsWithChildren<{}>
const drawerWidth = 220

const navigationOptions = [
    {
        name: 'Home',
        icon: <HomeIcon />,
        href: '/home',
        pathnameRoot: '/home',
    },
    {
        name: 'Bell Tower',
        icon: <CellTowerIcon />,
        href: '/belltower/live',
        pathnameRoot: '/belltower',
    },
    {
        name: 'Scheduling',
        icon: <DateRangeIcon />,
        href: '/schedule',
        pathnameRoot: '/schedule',
    },
    {
        name: 'Notices',
        icon: <AnnouncementIcon />,
        href: '/notices',
        pathnameRoot: '/notices',
    },
    // {
    //     name: 'Billing',
    //     icon: <RequestQuoteIcon />,
    //     href: '/billing',
    //     pathnameRoot: '/billing',
    // },
]

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

const StyledFooter = styled('footer')({
    display: 'flex',
    flex: 1,
    padding: ' 2rem 0',
    borderTop: '1px solid #eaeaea',
    justifyContent: 'center',
    alignItems: 'center',
})

const MainLayoutStylingWithBreakpoints = {
    marginTop: {
        xs: 2,
        lg: 2,
        xl: '32px',
    },
    marginRight: {
        xs: 2,
        lg: 4,
        xl: '96px',
    },
    marginLeft: {
        xs: 10,
        xl: '96px',
    },
    marginBottom: {
        xs: 2,
        lg: 4,
    },
    paddingBottom: {
        xs: '50px !important',
        lg: 0,
    },
    padding: {
        lg: 0,
    },
    flexGrow: 1,
    bgcolor: 'background.default',
}

function getFirstPathSeg(pathname: string) {
    try {
        return `/${pathname.split('/')[1]}`
    } catch (error) {
        return `/`
    }
}

const navOptFilter = () => true

export const ClientPortalLayout = ({ children, ...props }: LayoutProps) => {
    const { logout } = useAuth0()
    const router = useRouter()
    const [open, setOpen] = React.useState(false)

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    zIndex: 11,
                }}
            >
                <List>
                    <ListItem
                        onClick={() => setOpen(!open)}
                        aria-label="open drawer"
                        component="a"
                    >
                        <ListItemIcon>
                            <MenuIcon />
                        </ListItemIcon>
                    </ListItem>
                    {navigationOptions
                        .filter(navOptFilter)
                        .map((navigationOption) => (
                            <NextLink
                                href={navigationOption.href}
                                key={navigationOption.name}
                                passHref
                                legacyBehavior
                            >
                                <ListItem
                                    key={navigationOption.name}
                                    component="a"
                                    sx={{
                                        '&.Mui-selected': {
                                            backgroundColor:
                                                'rgba(129, 136, 157, 0.24)',
                                        },
                                    }}
                                    selected={
                                        getFirstPathSeg(router.pathname) ==
                                        getFirstPathSeg(navigationOption.href)
                                    }
                                >
                                    <ListItemIcon>
                                        {navigationOption.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={navigationOption.name}
                                    />
                                </ListItem>
                            </NextLink>
                        ))}
                </List>

                <List style={{ marginTop: `auto` }}>
                    <ListItem>
                        <StyledFooter>
                            <ListItem
                                key={'Logout'}
                                component="a"
                                sx={{
                                    '&.Mui-selected': {
                                        backgroundColor:
                                            'rgba(129, 136, 157, 0.24)',
                                    },
                                }}
                                onClick={() =>
                                    logout({
                                        returnTo: window.location.origin,
                                    })
                                }
                            >
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Logout'} />
                            </ListItem>
                        </StyledFooter>
                    </ListItem>
                </List>
            </Drawer>

            <Box component="main" sx={MainLayoutStylingWithBreakpoints}>
                {children}
            </Box>
        </Box>
    )
}
