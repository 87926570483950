import '../../src/shared/styles/globals.css'

import 'react-phone-input-2/lib/material.css'
import React, { ReactNode } from 'react'
import { ClientPortalLayout } from '../components/Layouts/ClientPortalLayout'
import { JustChildren } from '../../src/components/AuthWrapper'
import Head from 'next/head'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import Router from 'next/router'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider, CssBaseline } from '@mui/material'
import createEmotionCache from '../../src/utils/createEmotionCache'
import lightTheme from '../../src/shared/styles/theme/lightTheme'
import { SWRConfigWrapper } from '../../src/components/SWRConfig'
import 'react-toastify/dist/ReactToastify.css'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { ToastContainer } from 'react-toastify'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FirmInfoContextWrapperForClientPortal } from '../../src/components/FirmSettingContext/FirmSettingContext'

const RouteDependentClientPortalAuthWrapper = dynamic(
    // @ts-ignore
    () =>
        import('../../src/components/AuthWrapper').then(
            (mod) => mod.RouteDependentClientPortalAuthWrapper
        ),
    { ssr: false }
)

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DD_APP_ID!,
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        service: process.env.NEXT_PUBLIC_DD_SERVICE!,
        env: process.env.NEXT_PUBLIC_DD_ENV!,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: 'allow',
        trackUserInteractions: true,
        allowedTracingUrls: [
            process.env.NEXT_PUBLIC_DD_ALLOWED_TRACING_ORIGINS!,
        ],
    })
    datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        forwardConsoleLogs: ['error'],
        service: 'nextjs-client-portal-web-app',
    })
}

const clientSideEmotionCache = createEmotionCache()

const onRedirectCallback = (appState?: AppState | undefined) => {
    Router.replace(appState?.returnTo || '/')
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
    return (
        <Auth0Provider
            domain={process.env.NEXT_PUBLIC_DOMAIN ?? ''}
            clientId={process.env.NEXT_PUBLIC_CLIENT_PORTAL_CLIENT_ID ?? ''}
            audience={process.env.NEXT_PUBLIC_AUDIENCE}
            scope="read:users"
            redirectUri={
                typeof window !== 'undefined'
                    ? window.location.origin
                    : undefined
            }
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

const ClientPortalContent = ({
    Component,
    pageProps,
    authEnabled,
}: {
    Component: any
    pageProps: any
    authEnabled: boolean
}) => {
    const getLayout = Component.getLayout || ((page: ReactNode) => page)
    const MaybeClientPortalLayout = authEnabled
        ? ClientPortalLayout
        : JustChildren

    return (
        <SWRConfigWrapper>
            <MaybeClientPortalLayout>
                <Head>
                    <title>Belfry</title>
                    <meta name="description" content="Belfry" />
                    <link rel="icon" href="/favicon.svg" type="image/svg+xml" />

                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                </Head>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    theme="colored"
                    pauseOnHover
                />

                {getLayout(<Component {...pageProps} />)}
            </MaybeClientPortalLayout>
        </SWRConfigWrapper>
    )
}

const MyApp = (props: {
    Component: any
    emotionCache?: any
    pageProps: any
}) => {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        pageProps,
    } = props
    const router = useRouter()

    const authEnabled = !(
        router.asPath === '/' ||
        router.query.paymentRequestId ||
        router.pathname === '/payments/[paymentRequestId]/payment' ||
        router.pathname === '/reports/[reportId]/download'
    )

    return (
        <AuthProvider>
            <CacheProvider value={emotionCache}>
                <ThemeProvider theme={lightTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        {/* @ts-ignore */}
                        <RouteDependentClientPortalAuthWrapper>
                            {authEnabled ? (
                                <FirmInfoContextWrapperForClientPortal>
                                    <ClientPortalContent
                                        Component={Component}
                                        pageProps={pageProps}
                                        authEnabled={authEnabled}
                                    />
                                </FirmInfoContextWrapperForClientPortal>
                            ) : (
                                <ClientPortalContent
                                    Component={Component}
                                    pageProps={pageProps}
                                    authEnabled={authEnabled}
                                />
                            )}
                        </RouteDependentClientPortalAuthWrapper>
                    </LocalizationProvider>
                </ThemeProvider>
            </CacheProvider>
        </AuthProvider>
    )
}

export default MyApp
