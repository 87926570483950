import React from 'react'
import { OpenAPI } from '../../generated'
import { useAuth0 } from '@auth0/auth0-react'
import { withAuthenticationRequired, User } from '@auth0/auth0-react'
import { Loading } from '../Loading'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { datadogRum } from '@datadog/browser-rum'

OpenAPI.BASE = process.env.NEXT_PUBLIC_API_ENDPOINT_NEW ?? ''

const ROLES_CLAIM = 'http://belfry.roles.com/roles'
const ADMIN_APP_ALLOWED_ROLES = ['Admin', 'Manager', 'Supervisor', 'HR']
const ClIENT_PORTAL_ALLOWED_ROLES = [
    'Admin',
    'Manager',
    'Supervisor',
    'Client',
    'HR',
]
const ONBOARDING_PORTAL_ALLOWED_ROLES = [
    'Admin',
    'Manager',
    'Supervisor',
    'Officer',
    'HR',
]

const AuthWrapperR = ({
    children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
    const { getAccessTokenSilently, user, isLoading } = useAuth0()
    if (isLoading) {
        return <Loading />
    }

    if (user) {
        const userInfo = {
            auth0_id: user.sub,
            org_id: user.org_id,
            email: user.email,
        }
        Sentry.setUser(userInfo)
        datadogRum.setUser(userInfo)
        // @ts-ignore
        if (typeof window !== 'undefined' && window.$crisp) {
            // @ts-ignore
            window?.$crisp?.push(['set', 'user:company', [user.org_id]])
            // @ts-ignore
            window?.$crisp?.push(['set', 'user:email', [user.email]])
        }
    }

    const getTokenOpenAPI = async () => {
        const token = await getAccessTokenSilently()
        if (token == null) {
            return ''
        } else {
            return token
        }
    }
    OpenAPI.TOKEN = getTokenOpenAPI

    return <>{children}</>
}

const adminAppCheckRoles = (claims?: User): boolean => {
    /*
     * Don't allow just any user in the system to access the web app. Ensure they have an allowed role.
     */
    if (
        claims &&
        ROLES_CLAIM in claims &&
        claims[ROLES_CLAIM].filter((roleClaim: string) =>
            ADMIN_APP_ALLOWED_ROLES.includes(roleClaim)
        ).length !== 0
    ) {
        return true
    }
    return false
}

const clientPortalCheckRoles = (claims?: User): boolean => {
    /*
     * Don't allow just any user in the system to access the web app. Ensure they have an allowed role.
     */
    if (
        claims &&
        ROLES_CLAIM in claims &&
        claims[ROLES_CLAIM].filter((roleClaim: string) =>
            ClIENT_PORTAL_ALLOWED_ROLES.includes(roleClaim)
        ).length !== 0
    ) {
        return true
    }
    return false
}

const onboardingPortalCheckRoles = (claims?: User): boolean => {
    /*
     * Don't allow just any user in the system to access the web app. Ensure they have an allowed role.
     */
    if (
        claims &&
        ROLES_CLAIM in claims &&
        claims[ROLES_CLAIM].filter((roleClaim: string) =>
            ONBOARDING_PORTAL_ALLOWED_ROLES.includes(roleClaim)
        ).length !== 0
    ) {
        return true
    }
    return false
}

export const AuthWrapper = withAuthenticationRequired(AuthWrapperR, {
    onRedirecting: () => <Loading />,
    claimCheck: adminAppCheckRoles,
})

export const ClientPortalAuthWrapper = withAuthenticationRequired(
    AuthWrapperR,
    {
        onRedirecting: () => <Loading />,
        claimCheck: clientPortalCheckRoles,
    }
)

export const OnboardingPortalAuthWrapper = withAuthenticationRequired(
    AuthWrapperR,
    {
        onRedirecting: () => <Loading />,
        claimCheck: onboardingPortalCheckRoles,
    }
)

export const JustChildren = ({
    children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
    return <>{children}</>
}

// All of this is so we can exclude certain paths from being protected
export const RouteDependentClientPortalAuthWrapper = ({
    children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
    const router = useRouter()
    const MaybeAuthWrapper = [
        '/login',
        '/',
        '/payments/[paymentRequestId]/payment',
        '/reports/[reportId]/download',
    ].includes(router.pathname)
        ? JustChildren
        : ClientPortalAuthWrapper
    // const MaybeAuthWrapper =   JustChildren
    return <MaybeAuthWrapper>{children}</MaybeAuthWrapper>
}

// All of this is so we can exclude certain paths from being protected
export const RouteDependentOnboardingPortalAuthWrapper = ({
    children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
    const router = useRouter()
    const MaybeAuthWrapper = ['/login', '/'].includes(router.pathname)
        ? JustChildren
        : OnboardingPortalAuthWrapper
    // const MaybeAuthWrapper =   JustChildren
    return <MaybeAuthWrapper>{children}</MaybeAuthWrapper>
}
